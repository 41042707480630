import React from 'react';
import clsx from 'clsx';
import {IconChecked} from '../Icon';
import {InlineLoading} from '../../Internal/InlineLoading';

import styles from './Input.module.css';
import {detectDevice} from '../Device';

export type InputType =
  | 'text'
  | 'tel'
  | 'search'
  | 'url'
  | 'email'
  | 'hidden'
  | 'password'
  | 'number';

export type ValidState = 'loading' | 'valid';

export interface Props
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'className'> {
  highlight?: boolean;
  icon?: React.ReactElement<React.ReactSVGElement>;
  isError?: boolean;
  isRequired?: boolean;
  validIconState?: ValidState;
  disableIconWhenDisabledInput?: boolean;
  iconPosition?: 'left' | 'right';
  visuallyDisabled?: boolean;
  blurText?: boolean;
}

export const Input = React.forwardRef(
  (props: Props, ref?: React.Ref<HTMLInputElement>) => {
    const {
      isError = false,
      validIconState,
      isRequired,
      disabled,
      disableIconWhenDisabledInput = true,
      readOnly,
      highlight = false,
      icon,
      iconPosition = 'left',
      visuallyDisabled,
      blurText,
      // override `onFocus` with `handleFocus`
      onFocus,
      ...restProps
    } = props;
    const [isHiglighted, setIsHighlighted] = React.useState(highlight);
    const {os} = detectDevice(navigator.userAgent);
    const inputClassName = clsx(styles.input, {
      [styles.disabled]: disabled || visuallyDisabled,
      [styles.readOnly]: readOnly,
      [styles.isError]: isError,
      [styles.withValidIcon]: validIconState,
      [styles.highlight]: isHiglighted,
      [styles.withPrimaryIconLeft]: Boolean(icon) && iconPosition === 'left',
      [styles.withPrimaryIconRight]: Boolean(icon) && iconPosition === 'right',
      [styles.blurText]: blurText,
      [styles.inputiOS]: os === 'ios',
    });

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (isHiglighted) {
        setIsHighlighted(false);
      }
      onFocus?.(event);
    };

    return (
      <div className={styles.root}>
        {icon && (
          <div
            className={clsx(styles.primaryIcon, {
              [styles.primaryIconLeft]: iconPosition === 'left',
              [styles.primaryIconRight]: iconPosition === 'right',
              [styles.disabled]:
                (disabled || visuallyDisabled) && disableIconWhenDisabledInput,
            })}
          >
            {icon}
          </div>
        )}
        <input
          className={inputClassName}
          ref={ref}
          aria-required={isRequired}
          disabled={disabled}
          readOnly={readOnly}
          onFocus={handleFocus}
          aria-invalid={Boolean(isError)}
          {...restProps}
        />
        {validIconState === 'valid' &&
          !isError &&
          !disabled &&
          !visuallyDisabled && (
            <IconChecked className={styles.validIcon} size={24} />
          )}
        {validIconState === 'loading' && !disabled && !visuallyDisabled && (
          <div className={styles.progressIcon}>
            <InlineLoading />
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';
