import {
  CallbackParams,
  NotifyOptions,
  Reason,
  WretchError,
  httpPostJson,
  mParticleEventType,
  mParticleLogEvent,
} from '@treatwell/ui';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { useCallback } from 'react';

export type LoginParams = {
  password?: string;
  googleToken?: string;
  oneTapToken?: string;
  email?: string;
  facebookToken?: string;
  appleAuthorizationCode?: string;
  referralCode?: string;
  o2OToken?: string;
};

type SuccessResponse = {
  authTokens: {
    'X-ITKT-TOKEN': string;
    'X-ATKT-TOKEN': string;
  };
  accountId: {
    id: number;
  };
};

export type ErrorResponse = {
  errors: {
    type: string;
    name: string;
    message: string;
  }[];
};

export function isErrorResponse(
  error: Error | ErrorResponse
): error is ErrorResponse {
  return (
    typeof error === 'object' &&
    'errors' in error &&
    Array.isArray(error.errors)
  );
}

type LoginResponse = SuccessResponse | ErrorResponse;

export const fetchLogin = (body: LoginParams = {}) => {
  return httpPostJson<LoginResponse>({
    path: '/api/v2/me/login',
    body: {
      ...body,
      cookies: true,
    },
  });
};

type TriggerContext = 'homepage' | 'browse' | 'venue';
export const trackGoogleLoginError = ({
  countryCode,
  isWebMobile,
  triggerContext,
  error,
}: {
  countryCode: string;
  isWebMobile: boolean;
  triggerContext?: TriggerContext;
  error: string;
}) => {
  mParticleLogEvent('user_identification_failed', mParticleEventType.Other, {
    country: countryCode,
    platform: 'web',
    is_web_mobile: isWebMobile,
    identification_method: 'login',
    authentication_method: 'google-one-tap',
    trigger_context: triggerContext,
    error,
  });
};

export const handleCredentialResponse = ({ credential }: CallbackParams) => {
  return fetchLogin({ oneTapToken: credential });
};

export const onSuccess = () => {
  window.location.reload();
};

export const getTrackingEventErrorMessage = (error: Error | WretchError) => {
  let message;

  try {
    const errorMessage = JSON.parse(error.message);
    if (isErrorResponse(errorMessage)) {
      message = errorMessage.errors[0]?.name;
    } else {
      message = errorMessage;
    }
  } catch {
    message = error;
  }

  return message;
};

// TODO: this will need to be expanded to take into account the duplicated account error messages:
// google_needs_password_authentication (MP-2600)
// For the other errors: not_authenticated, google_not_recognised and generic 500 we can just
// show a Toast with a generic error message

export const useOnError = ({
  channel,
  genericError,
  isMobile,
  notifyError,
  eventTriggerContext,
  onTriggerVerifyModal,
}: {
  channel: Channel;
  genericError: string;
  isMobile: boolean;
  notifyError: (message: string, options?: NotifyOptions) => void;
  eventTriggerContext?: TriggerContext;
  onTriggerVerifyModal: () => void;
}) => {
  return useCallback(
    (error: Error | WretchError) => {
      const message = getTrackingEventErrorMessage(error);

      trackGoogleLoginError({
        countryCode: channel.country.countryCode,
        isWebMobile: isMobile,
        triggerContext: eventTriggerContext,
        error: message,
      });

      if (message === 'google_needs_password_authentication') {
        onTriggerVerifyModal();
      } else {
        notifyError(genericError);
      }
    },
    // notifyError is not added in the dependency array
    // because it is not stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      channel.country.countryCode,
      eventTriggerContext,
      genericError,
      isMobile,
      onTriggerVerifyModal,
    ]
  );
};

export const onModalDisplay = ({
  countryCode,
  isWebMobile,
  triggerContext,
  auto,
}: {
  countryCode: string;
  isWebMobile: boolean;
  triggerContext?: TriggerContext;
  auto?: true;
}) => {
  mParticleLogEvent('google_one_tap_viewed', mParticleEventType.Other, {
    country: countryCode,
    platform: 'web',
    is_web_mobile: isWebMobile,
    trigger_context: triggerContext,
    is_google_one_tap_automatic_log_in: auto || false,
  });
};

export const onModalDismiss = ({
  reason,
  countryCode,
  isWebMobile,
  triggerContext,
}: {
  reason: Reason;
  countryCode: string;
  isWebMobile: boolean;
  triggerContext?: TriggerContext;
}) => {
  if (
    reason === 'user_cancel' ||
    reason === 'cancel_called' ||
    reason === 'tap_outside'
  ) {
    mParticleLogEvent('google_one_tap_closed', mParticleEventType.Other, {
      country: countryCode,
      platform: 'web',
      is_web_mobile: isWebMobile,
      trigger_context: triggerContext,
      close_reason: reason,
    });
  }
};
