import {
  mParticleLogPageView,
  mParticleTrackSessionAttributes,
} from '@treatwell/ui';
import React from 'react';
import { Context } from '../components/LocaleWrapper';

const UTM_FIELDS = [
  'utm_campaign',
  'utm_content',
  'utm_marketing',
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_campaign_id',
  'utm_ad_id',
  'gclid',
];

const getNavigationEntry = (): PerformanceNavigationTiming | void => {
  const navigationEntry =
    window.performance &&
    performance.getEntriesByType &&
    (performance.getEntriesByType(
      'navigation'
    )[0] as PerformanceNavigationTiming);

  // Check to ensure the `responseStart` property is present and valid.
  // In some cases no value is reported by the browser (for
  // privacy/security reasons), and in other cases (bugs) the value is
  // negative or is larger than the current page time.
  if (
    navigationEntry &&
    navigationEntry.responseStart > 0 &&
    navigationEntry.responseStart < performance.now()
  ) {
    return navigationEntry;
  }
};

export function getUtmParameters(parameters: string) {
  const utmParameters = Object.fromEntries(
    [...new URLSearchParams(parameters).entries()].filter(([key]) =>
      UTM_FIELDS.includes(key)
    )
  );
  return utmParameters;
}

type PageLoadMetrics = {
  ['full_page_load_time']: string;
  ['server_response_time']: string;
  ['dom_content_load_time']: string;
  ['ttfb_load_time']: string;
  ['dom_complete_load_time']: string;
};

const getReportPageLoadMetrics = (): PageLoadMetrics | void => {
  const navigationEntry = getNavigationEntry();

  if (!navigationEntry) {
    return;
  }

  return {
    full_page_load_time: (
      navigationEntry.loadEventEnd - navigationEntry.startTime
    ).toFixed(2),
    server_response_time: (
      navigationEntry.responseEnd - navigationEntry.fetchStart
    ).toFixed(2),
    dom_content_load_time: (
      navigationEntry.domContentLoadedEventEnd - navigationEntry.startTime
    ).toFixed(2),
    ttfb_load_time: (
      navigationEntry.responseStart - navigationEntry.startTime
    ).toFixed(2),
    dom_complete_load_time: (
      navigationEntry.domComplete - navigationEntry.startTime
    ).toFixed(2),
  };
};

export function useTrackPageAndSessionView(
  venueId?: number,
  treatment?: string,
  treatmentType?: string,
  hasPopularTreatments?: boolean
) {
  const { channel } = React.useContext(Context);

  React.useEffect(() => {
    const eventName = venueId ? 'venue_menu' : 'page_view';
    const utmParameters = getUtmParameters(window.location.search);
    const loadSpeedMetrics = venueId ? getReportPageLoadMetrics() : undefined;

    mParticleLogPageView(eventName, {
      country: channel?.country?.countryCode,
      referrer: document.referrer,
      url: window.location.href,
      url_path: window.location.pathname,
      query_parameters: window.location.search,
      platform: 'web',
      section: venueId ? 'booking_flow' : undefined,
      venue_id: venueId ? venueId.toString() : undefined,
      treatment,
      treatment_type: treatmentType,
      popular_treatments_displayed: hasPopularTreatments,
      ...utmParameters,
      ...loadSpeedMetrics,
    });

    mParticleTrackSessionAttributes(
      channel?.country?.countryCode,
      utmParameters
    );
  }, [
    channel?.country?.countryCode,
    hasPopularTreatments,
    treatment,
    treatmentType,
    venueId,
  ]);
}

interface Props {
  children: React.ReactNode;
  treatment?: string;
  treatmentType?: string;
  hasPopularTreatments?: boolean;
  venueId?: number;
}

export const TrackPageView = ({
  children,
  venueId,
  treatment,
  treatmentType,
  hasPopularTreatments,
}: Props) => {
  useTrackPageAndSessionView(
    venueId,
    treatment,
    treatmentType,
    hasPopularTreatments
  );
  return children;
};
