import React from 'react';
import {AllowedTags, Text} from '../Text';
import styles from './FormError.module.css';

export type Props = {
  errorText?: string;
  as?: AllowedTags;
};

export const FormError = ({errorText, as = 'span'}: Props) => {
  return errorText ? (
    <Text as={as} className={styles.error} type="captionHeavy">
      {errorText}
    </Text>
  ) : null;
};
