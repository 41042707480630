import {ChangeEvent} from 'react';
import {Button, FormError, Input, Text} from '../../Atoms';
import {Stack} from '../../Layout';
import {Modal, useModal} from '../Modal';
import styles from './VerifyPasswordModal.module.css';

export type VerifyPasswordModalProps = {
  labels: {
    description: string;
    placeholder: string;
    cta: {
      verify: string;
      cancel: string;
    };
  };
  onToggle: (isOpen: boolean) => void;
  onVerifyClick: () => void;
  passwordValue: string;
  onPasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
  errorText?: string;
  isOpen: boolean;
};

export const VerifyPasswordModal = ({
  labels,
  onToggle,
  onVerifyClick,
  passwordValue = '',
  onPasswordChange,
  errorText,
  isOpen,
}: VerifyPasswordModalProps) => {
  const {modalProps, closeModal} = useModal({
    width: 500,
    height: 500,
    onToggle,
  });

  return (
    <Modal {...modalProps} isOpen={isOpen} data-cy="VerifyPasswordModal">
      <Modal.Body>
        <div className={styles.modalContent}>
          <Stack space="xxl" align="center">
            <Stack space="lg" align="center">
              <Text
                as="label"
                htmlFor="verify-password-input"
                type="mdHeader"
                className={styles.text}
              >
                {labels.description}
              </Text>
              <div className={styles.inputContainer}>
                <Stack space="md">
                  <Input
                    id="verify-password-input"
                    value={passwordValue}
                    onChange={(event) => {
                      onPasswordChange(event);
                    }}
                    type="password"
                    placeholder={labels.placeholder}
                    isError={Boolean(errorText)}
                  />
                  {errorText && <FormError errorText={errorText} />}
                </Stack>
              </div>
              <Button fullWidth onClick={onVerifyClick}>
                {labels.cta.verify}
              </Button>
            </Stack>
            <div className={styles.cancelButton}>
              <Button fullWidth onClick={closeModal}>
                {labels.cta.cancel}
              </Button>
            </div>
          </Stack>
        </div>
      </Modal.Body>
    </Modal>
  );
};
