import { useGrowthBook } from '@growthbook/growthbook-react';
import {
  useNotify,
  useDevice,
  GoogleOneTapSignIn,
  VerifyPasswordModal,
  Reason,
} from '@treatwell/ui';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { isProductionEnvironment } from './helpers/environment';
import {
  useOnError,
  handleCredentialResponse,
  onSuccess,
  onModalDisplay,
  onModalDismiss,
  fetchLogin,
  getTrackingEventErrorMessage,
  trackGoogleLoginError,
} from './helpers/googleOneTap/googleOneTap';
import { LoginStatus } from './model/rainbow/AccountDetailsOutput';
import { StateData } from './model/rainbow/StateData';

export const ExperimentDisplayGoogleOneTap = ({
  pageData,
}: {
  pageData: StateData;
}) => {
  const { notifyError } = useNotify();
  const { t } = useTranslation();
  const genericError = t('error.generic');
  const accountDetails = pageData.accountDetails;
  const gb = useGrowthBook();
  const { isMobile } = useDevice();
  let isGoogleOneTapExperimentOn = false;
  const shouldShowGoogleOneTap = /venue|homepage|browse/.test(
    pageData.pageType
  );
  const triggerContext = shouldShowGoogleOneTap
    ? (pageData.pageType as 'homepage' | 'browse' | 'venue')
    : undefined;
  const countryCode = pageData.channel.country.countryCode;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [input, setInputValue] = useState('');
  const [googleToken, setGoogleToken] = useState('');
  const [errorText, setErrorText] = useState<string>();

  const handleTriggerVerifyModal = useCallback(() => setIsModalOpen(true), []);
  const onHandleError = useOnError({
    channel: pageData.channel,
    genericError,
    isMobile,
    notifyError,
    eventTriggerContext: triggerContext,
    onTriggerVerifyModal: handleTriggerVerifyModal,
  });

  const onVerifyClick = () => {
    setErrorText(undefined);
    fetchLogin({ oneTapToken: googleToken, password: input })
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        setErrorText(genericError);
        const message = getTrackingEventErrorMessage(error);

        trackGoogleLoginError({
          countryCode,
          isWebMobile: isMobile,
          triggerContext,
          error: `Password verification: ${message}`,
        });
      });
  };

  const handleOnGoogleToken = useCallback((token: string) => {
    setGoogleToken(token);
  }, []);

  const handleOnModalDisplay = useCallback(
    (args?: { auto: true }) => {
      onModalDisplay({
        countryCode,
        isWebMobile: isMobile,
        triggerContext,
        auto: args !== undefined ? args?.auto : undefined,
      });
    },
    [countryCode, isMobile, triggerContext]
  );

  const handleOnModalDismiss = useCallback(
    (reason: Reason) =>
      onModalDismiss({
        reason,
        countryCode,
        isWebMobile: isMobile,
        triggerContext,
      }),
    [countryCode, isMobile, triggerContext]
  );

  if (accountDetails.loginStatus === LoginStatus.notRecognised) {
    isGoogleOneTapExperimentOn =
      gb.getFeatureValue('ab-web-multiple__google-one-tap', 'none') ===
      'variant-1';
  }

  return (
    <>
      {shouldShowGoogleOneTap && isGoogleOneTapExperimentOn && (
        <>
          <GoogleOneTapSignIn
            environment={isProductionEnvironment ? 'prod' : 'dev'}
            handleCredentialResponse={handleCredentialResponse}
            onError={onHandleError}
            onSuccess={onSuccess}
            onGoogleToken={handleOnGoogleToken}
            onModalDisplay={handleOnModalDisplay}
            onModalDismiss={handleOnModalDismiss}
          />
          <VerifyPasswordModal
            labels={{
              description: t('verifyPassword.description'),
              placeholder: t('verifyPassword.placeholder'),
              cta: {
                verify: t('verifyPassword.cta.submit'),
                cancel: t('verifyPassword.cta.cancel'),
              },
            }}
            onToggle={state => setIsModalOpen(state)}
            onVerifyClick={onVerifyClick}
            passwordValue={input}
            onPasswordChange={event => {
              setInputValue(event.currentTarget.value);

              if (errorText) {
                setErrorText(undefined);
              }
            }}
            isOpen={isModalOpen}
            errorText={errorText}
          />
        </>
      )}
    </>
  );
};
